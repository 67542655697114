/* ==========================================================================
 Variables
 ========================================================================== */
$color-base: #404A54;
$color-text: #404A54;
$color-subtle: #7F7F7F;
$color-accent: #687E92;
$color-headings: $color-text;
$color-accent-secondary: #CC9E60;
$color-accent-third: #B7B7B7;
$color-neutral: #A0A0A0;
$color-body-grey: #B5B5B5;
$color-light-text: #78889C;
$color-light-text-forms: rgba(0, 0, 0, .45);
$color-light: #EFEFEF;
$color-grey: #E5E5E5;
$color-subtle-background: #F7F5F2;
$color-subtle-background-secondary: #EBF3F7;
$color-on-base: rgba(255, 255, 255, .4);
$color-grey-on-base: #808C8A;
$color-light-box: #F7F7F7;
$color-hr: #D7DDD9;

$color-hamburger: $color-base;
$color-hamburger-menu-open: #fff;


$font-sans: "basic-sans", sans-serif;
$font-serif: "basic-sans", sans-serif;
$font-body: "basic-sans", sans-serif;
$font-headline: 'kepler-std-condensed-subhead', sans-serif;
$font-menu: 'kepler-std-condensed-subhead', sans-serif;
$images: "../../assets/images";


section {
  //border: 1px solid $color-hr;
}

html body main.main {
  padding: 0;
}



.container-fluid.mgtop {
  margin-top: 40px;
}





/* =================================================
     Regular tag styling
   ============================================== */
body {
  background: #F4F2F0;
  background: #F4F2F0 url("#{$images}/paper.jpg") center center repeat;
  background-size: 300px auto;
}

body,
html {
  height: 100%;
  padding: 0;
  margin: 0;
}

body,
input,
li,
td,
textarea {
  font-family: $font-sans;
  font-size: 16.3px;
  color: $color-text;
  font-weight: 300;
  font-feature-settings: "liga1" on;
  font-variant: common-ligatures, oldstyle-nums;
  html.wf-active & {
    font-size: 16.5px;
    //line-height: 100%;
  }
}
@media handheld, only screen and (max-width: 767px) {
  body,
  input,
  li,
  td,
  textarea {
    font-size: 16px;
  }
}

.wrapper {
  width: 100%;
}

p {
  margin: 0 0 1.3em;
  line-height: 180%;
}

b {
  font-weight: normal;
}

::selection {
  background: $color-accent;
  /* Safari */
  color: #fff;
}

::-moz-selection {
  background: $color-accent;
  /* Firefox */
  color: #fff;
}

ol li,
ul li {
  margin-bottom: 8px;
}

ul li h4 {
  display: inline-block;
}

th {
  font-family: $font-sans, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 20px;
  line-height: 110%;
  color: $color-headings;
  font-family: $font-headline;
  font-weight: 200;
}

h1 {
  font-size: 30px;
  line-height: 133%;
  html.wf-active & {
    font-size: 40px;
    line-height: 100%;
  }
  margin: 0 0 25px;
  font-style: normal;
  font-weight: 300;

  span {
    display: block;
    color: $color-base;
  }
}

h2 {
  font-size: 23px;
  font-weight: 300;
  line-height: 220%;
  //text-transform: uppercase;

  html.wf-active & {
    font-size: 40px;
    line-height: 130%;
  }
  @media handheld, only screen and (max-width: 768px) {
    font-size: 20px;
  }
}

h3 {
  margin-bottom: 15px;
  line-height: 150%;
  font-size: 20px;

  html.wf-active & {
    font-size: 29px;
    line-height: 120%;
  }
}

h4 {
  font-size: 24px;
  margin: 0 0 14px;
}

h5 {
  font-size: 22px;
}

h6 {
  font-size: 20px;
}

blockquote {
  border-left: 6px solid $color-accent;
  padding-left: 30px;
}

.hl {
  font-size: 20px;
  color: rgba(0, 0, 0, .45);
}

.hl-alt {
  font-size: 18px;
}
// Breakpoint #3
@media handheld, only screen and (max-width: 768px) {
  .hl {
    font-size: 18px;
  }
}

hr {
  height: 1px;
  border: none;
  border-bottom: 1px solid $color-hr;
  background: transparent;
  margin: 40px 0;
  clear: both;
}

hr.sm-pd {
  margin: 20px 0;
}
// Color bar divider.

hr.mini {
  width: 30px;
  margin: 20px 0;
  display: block;
  border-bottom: 2px solid $color-accent;
  text-align: left;

  &.c2 {
    border-color: $color-accent-secondary;
  }

  &.mgtop {
    margin-top: 50px;
  }

  &.mgbot {
    margin-bottom: 50px;
  }
}

hr.ctr {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

b,
strong {
  font-weight: 600;
}

.sub {
  font-size: 13px;
  letter-spacing: 0.1em;
  line-height: 100%;
  margin: 0 0 30px;
  display: block;
  color: $color-accent-secondary;
}
// Mobile
@media handheld, only screen and (max-width: 767px) {
  .sub {
    font-size: 12px;
    line-height: 100%;
    margin: 0 0 8px;
  }
}

i {
  font-style: italic;
}

a:active,
a:hover,
a:link,
a:visited {
  color: $color-accent-secondary;
  text-decoration: none;
  @include transition(.2s);
  padding-bottom: 0;
  border-bottom: none;
}

a:hover {
  color: #000;
}

fieldset {
  border: 1px solid $color-hr;
  padding: 15px 0 0;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  margin: 0;

  .submit-container {
    background: $color-grey;
    padding: 15px;
    text-align: center;
  }
}

fieldset legend {
  padding: 10px;
  font-size: 18px;
  color: $color-accent;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  margin: 0 auto;
}

input,
textarea {
  padding: 9px;
  @include rounded(2px);
  background-color: #E7E7E6;
  @include inner-shadow(0);
}

small {
  color: #B9B9B9;
  font-size: 13px;
}
/* Images */
.comment-content img,
.entry-content img,
.widget img {
  max-width: 97.5%;
  /* Fluid images for posts, comments, and widgets */
}

img[class*="align"],
img[class*="wp-image-"] {
  height: auto;
  /* Make sure images with WordPress-added height and width >attributes are scaled correctly */
}

img.size-full {
  max-width: 97.5%;
  width: auto;
  /* Prevent stretching of full-size images with height and >>width attributes in IE8 */
}

.wp-caption {
  max-width: 100%;
}
/* Default table stuff. */
.th-default {
  background-color: $color-base;
  padding: 8px;
  font-family: $font-sans;
  text-align: left;
}

picture {
  display: block;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;

  img {
    width: 100%;
    display: block;
  }
}

p.h-hat {
  margin: 0 0 6px;
  font-size: 14px;
  color: #B1B9BA;
}
/* Designated lists. */
dl {
  margin: 0;
  padding: 0;

  dd,
  dt {
    display: block;
    text-align: left;
    float: none;
    width: auto;
    padding: 0;
    margin: 0;
    border: 0;
  }

  dt {
    padding-bottom: 10px;
  }

  dd {
    padding-bottom: 35px;
  }

  & dd:last-child {
    padding-bottom: 0;
  }
}
/* =================================================
  Standard mobile styles
============================================== */
// Breakpoint #1
@media handheld, only screen and (max-width: 1150px) {}
// Breakppint #2
@media handheld, only screen and (max-width: 1023px) {}
// Breakpoint #3
@media handheld, only screen and (max-width: 768px) {}
/* =================================================
     Page layout - widths, etc.
   ============================================== */
.container-fluid {
  max-width: 1600px;
  padding: 0;
}

.row {
  margin-left: auto;
  margin-right: auto;
  padding: 0 50px;
  @media handheld, only screen and (max-width: 1000px) {
    padding: 0 20px;
  }
  @media handheld, only screen and (max-width: 768px) {
    padding: 0;
  }
}





/*

This generates classes like this:
mt-3-md-up (Adds margin top to md cols of size 3)

The number is the size of the padding or margin 1 through 5.
*/

$class-prefix-list: mt mb; //mr and ml can be added if needed
$breakpoint-direction-list: up down;
$breakpoint-list: xs sm md lg;

@mixin spacer-dynamic($breakpoint, $direction, $size, $breakpoint-direction) {
@if($breakpoint-direction == 'up') {
    @include media-breakpoint-up($breakpoint) {
        @if $direction == 'mb' {
            margin-bottom: ($spacer/2) * ($size/2) !important;
        }

        @if $direction == 'mt' {
            margin-top: ($spacer/2) * ($size/2) !important;
        }

        @if $direction == 'mr' {
            margin-right: ($spacer/2) * ($size/2) !important;
        }

        @if $direction == 'ml' {
            margin-left: ($spacer/2) * ($size/2) !important;
        }
    }
}

@else if($breakpoint-direction == 'down') {
    @include media-breakpoint-down($breakpoint) {
        @if $direction == 'mb' {
            margin-bottom: ($spacer/2) * ($size/2) !important;
        }

        @if $direction == 'mt' {
            margin-top: ($spacer/2) * ($size/2) !important;
        }

        @if $direction == 'mr' {
            margin-right: ($spacer/2) * ($size/2) !important;
        }

        @if $direction == 'ml' {
            margin-left: ($spacer/2) * ($size/2) !important;
        }
    }
  }
}

@each $breakpoint in $breakpoint-list {
@each $breakpoint-direction in $breakpoint-direction-list {
    @each $direction in $class-prefix-list {
        @for $i from 1 through 6 {
            .#{$direction}-#{$i}-#{$breakpoint}-#{$breakpoint-direction} {
                @include spacer-dynamic($breakpoint, $direction, $i, $breakpoint-direction);
            }
        }
    }
}
}
