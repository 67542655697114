@import "grid.scss";



section.margin-top-sm {
  margin-top: 20px;
}
section.margin-top-md {
  margin-top: 50px;
}
section.margin-top-lg {
  margin-top: 100px;
}
section.margin-top-none {
  margin-top: 0;
}


section.margin-bot-sm {
  margin-bottom: 20px;
}
section.margin-bot-md {
  margin-bottom: 50px;
}
section.margin-bot-lg {
  margin-bottom: 100px;
}
section.margin-bot-none {
  margin-bottom: 0;
}
