


article.post {
  time, p.author {
    display: inline-block;
    font-size: 14px;
    color: $color-light-text;
  }
  span.del {
    color: $color-light-text;
    @include opacity(.5);
  }

}



section.post-filters {
  background: rgba(0, 0, 0, .03);
  padding: 30px 0;
  text-align: center;
}



.post-list {
  .row {
    .col-md-6 {
      border-right: 1px solid $color-hr;
      border-bottom: 1px solid $color-hr;
    }

    @include media-breakpoint-up(lg) {
      .col-md-6 {
        border-right: 1px solid $color-hr;
        border-bottom: 1px solid $color-hr;
      }
      .col-md-6:nth-child(3n) {
        border-right: none;
      }
      .col-md-6:nth-last-child(-n+3) {
        border-bottom: none;
      }
    }

    @include media-breakpoint-down(md) {
      .col-md-6 {
        border-right: 1px solid $color-hr;
        border-bottom: 1px solid $color-hr;
      }
      .col-md-6:nth-child(2n) {
        border-right: none;
      }
      .col-md-6:nth-last-child(-n+2) {
        border-bottom: none;
      }
    }
    @include media-breakpoint-down(sm) {
      .col-md-6 {
        border-right: 1px solid $color-hr;
        border-bottom: 1px solid $color-hr;
      }
      .col-md-6 {
        border-right: none;
      }
      .col-md-6:last-child {
        border-bottom: none;
      }
    }
  }
  article.post {
    heght: 100%;
    display: block;
    //min-height: 300px;
    @media handheld, only screen and (min-width: 1450px) {
      //min-height: 250px;
    }
    @include media-breakpoint-down(md) {
      //min-height: 270px;
    }



    header, .entry-summary {
      //padding: 0 15px;
    }

    header {
      padding-top: 15px;
      h2.entry-title {
        font-size: 20px;
        margin: 0 0 10px 0;
      }
    }
    .entry-summary {
      padding-bottom: 15px;
    }

  }
}
