



section.grid {
  img {
    display: block;
    margin: 0;
    padding: 0;
  }
}
