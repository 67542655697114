
@mixin butt {
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  border: none;
  padding: 15px 18px;
  @include transition(.2s ease-in-out);
  font-weight: 300;
  color: #fff;
  @include inner-shadow(none);
  @include box-shadow(none);
  text-transform: uppercase;
  font-size: 22px;
  letter-spacing: .1em;
  line-height: 100%;

  background: url("#{$images}/paper-background-brown-transparent.png") center center repeat;
  background-color: $color-accent-secondary;
  background-size: 135px auto;
  color: #fff;
  font-family: $font-headline;
  @include rounded(2px);
}

.butt,
a.butt:active,
a.butt:hover,
a.butt:link,
a.butt:visited,
button,
input.button {
  @include butt;
}

@mixin butt-hover {
  background-color: darken($color-accent-secondary, 10%);
  color: #fff;
}

a:hover.butt,
button:hover,
input.button:hover {
  @include butt-hover;
}
