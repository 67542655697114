//** Width of the `border` for generating carets that indicator dropdowns.
$caret-width-base: 4px; //** Carets increase slightly in size for larger components.
$caret-width-large: 5px;
/* =================================================
     Header
   ============================================== */


.main {
  clear: both;
  min-height: 93.5vh;

  /* Based on the height of the menu */
  @media handheld, only screen and (max-width: 1000px) {
    //padding-top: 53px;
    /* Based on the height of the menu */
  }
}

.wrap {
  min-height: 93.5vh;
  padding-top: 50px;
}

.header.top {
  //background-color: #fff;
}

.headroom {
  @include transition();
}
.headroom--pinned {
  transform: translateY(0%);
}
.headroom--unpinned {
  transform: translateY(-100%);
}



@media handheld, only screen and (max-width: 1000px) {
  .headroom--pinned {
    transform: none;
  }

  .headroom--unpinned {
    transform: none;
  }
}
// Main navigation.

.nav-primary {
  display: block;
  //margin-right: -17px;
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    margin-left: 50px;
    text-align: right;

    li {
      float: left;
      margin: 0;
      z-index: 5001;
      padding: 7px 10px;
      @media handheld, only screen and (min-width: 1250px) {
        padding: 7px 17px;
      }

      a:active,
      a:hover,
      a:link,
      a:visited {
        text-decoration: none;
        display: block;
        padding: 12px 0 9px;
        margin: 0;
        -moz-transition: none;
        -webkit-transition: none;
        -o-transition: color 0 ease-in;
        transition: none;
        font-weight: 400;
        color: $color-base;
        font-family: $font-menu;
        font-size: 15px;
        line-height: 150%;
        html.wf-active & {
          font-size: 22px;
          line-height: 100%;
        }
      }
    }
    // Hover.
    li.active a:active,
    li.active a:link,
    li.active a:visited {
      color: $color-accent;
    }
    // Active.
    li a:hover,
    li.active a:hover,
    li.hovered a {
      color: $color-accent;
    }

    li.menu-item-has-children {
      position: relative;
      @include transition();
      @media handheld, only screen and (max-width: 1000px) {
        position: static;
      }

      a:hover::after {
        display: none;
      }

      &:hover {
        background-color: $color-accent;

        a {
          color: #fff;
        }
      }
    }
    /* Sub menu */
    ul.sub-menu {
      display: none;
      position: absolute;
      z-index: 1001;
      background-color: $color-accent;
      width: 300px;
      left: 0;
      top: 45px;
      padding: 5px 0;
      margin: 0;
      @include drop-shadow();

      li {
        display: block;
        float: none;
        text-align: left;
        margin: 0;
        padding: 0;
      }

      a:active,
      a:hover,
      a:link,
      a:visited {
        color: #fff;
        display: block;
        padding: 9px 17px;
        //font-size: 19px;
      }

      a::after {
        display: none;
      }

      a:hover {
        background: $color-base;
      }

      b.back {
        display: none;
      }
      @media handheld, only screen and (max-width: 1000px) {
        display: block;
        position: static;
        width: 100%;
        height: 100%;
        top: 0;
        text-align: left;
        left: 0;
        padding: 0;
        display: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        background: $color-base;
        padding-top: 10px;
        padding-bottom: 10px;
        border-top: 1px solid rgba(255, 255, 255, .06);

        a:active,
        a:hover,
        a:link,
        a:visited {
          background: transparent;
          //padding-left: 30px;
        }

        b.back {
          color: #fff;
          padding: 15px;
          display: block;
          background: $color-base;
          cursor: pointer;
          display: none;
        }
      }
    }
  }
  // Breakpoint #3
  @media handheld, only screen and (max-width: 1000px) {
    padding: 36px 0 0;
    text-align: left;
    z-index: 2001;
    position: fixed;
    background-color: $color-accent;
    width: 250px;
    right: -250px;
    top: 0;
    height: 100%;
    margin: 0;
    z-index: 8000;
    @include transition(.3s);
    overflow: scroll;

    ul {
      margin-left: 0;
      text-align: left;

      li {
        display: block;
        float: none;
        margin: 0;
        padding: 0;
        border: 0;
        //position: relative;
        z-index: 5001;

        a:active,
        a:hover,
        a:link,
        a:visited {
          text-decoration: none;
          display: block;
          padding: 18px;
          @include transition();
          color: #fff;
          border: 0;
        }

        a:hover {
          background-color: transparent;
        }
      }

      li.active a::after {
        width: 7px;
        height: 7px;
        content: "";
        background-color: #fff;
        display: block;
        float: right;
        border-radius: 3px 3px 3px 3px;
        margin-top: 6px;
        display: none;
      }

      li.active {
        background-image: none;
      }

      li.active a {
        background: transparent;
      }
      // Hover.
      li a:hover,
      li.active a:active,
      li.active a:hover,
      li.active a:link,
      li.active a:visited,
      li.hovered a {
        color: #fff;
      }
    }
  }
}
// Mobile only menu items.

.nav-primary ul li.mobile-only {
  display: none;
}
// Desktop only menu items.

.nav-primary ul li.desktop-only {
  display: inline-block;
}
@media handheld, only screen and (max-width: 1000px) {
  // Mobile only menu items.
  .nav-primary ul li.mobile-only {
    display: block;
  }
  // Desktop only menu items.
  .nav-primary ul li.desktop-only {
    display: none;
  }
}
// Dropdown arrow/caret

.caret {
  display: inline-block;
  vertical-align: top;
  width: 0;
  height: 0;
  margin-left: 2px;
  margin-top: -3px;
  margin-right: 0;
  vertical-align: middle;
  border-top: $caret-width-base solid;
  border-right: $caret-width-base solid transparent;
  border-left: $caret-width-base solid transparent;
  @include transition();
  @media handheld, only screen and (max-width: 1000px) {
    float: right;
    margin-top: 7px;
    border-top: 7px solid;
    border-right: 7px solid transparent;
    border-left: 7px solid transparent;
    //display: none;
  }
}
@media handheld, only screen and (max-width: 1000px) {
  .hovered {
    .caret {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      transform: rotate(180deg);
    }
  }
}

#mobile-menu {
  cursor: pointer;
  display: none;
  position: fixed;
  z-index: 9999;
  top: 7px;
  right: 30px;
  @include media-breakpoint-down(xs) {
    right: 10px;
  }

  .menu-open & {
    //top: 0;
  }

  .tcon {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    cursor: pointer;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    height: 50px;
    transition: 0.3s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 30px;
    background: transparent;
    outline: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-tap-highlight-color: transparent;
  }

  .tcon > * {
    display: block;
  }

  .tcon:focus,
  .tcon:hover {
    outline: none;
  }

  .tcon::-moz-focus-inner {
    border: 0;
  }

  .tcon-menu__lines {
    display: inline-block;
    height: 2px;
    width: 30px;
    //border-radius: 2.85714px;
    transition: 0.3s;
    background: $color-hamburger;
    position: relative;
  }

  .tcon-menu__lines::after,
  .tcon-menu__lines::before {
    display: inline-block;
    height: 2px;
    width: 30px;
    //border-radius: 2.85714px;
    transition: 0.3s;
    background: $color-hamburger;
    content: '';
    position: absolute;
    left: 0;
    -webkit-transform-origin: 2.85714px center;
    transform-origin: 2.85714px center;
    width: 100%;
  }

  .tcon-menu__lines::before {
    top: 10px;
  }

  .tcon-menu__lines::after {
    top: -10px;
  }

  .tcon-transform .tcon-menu__lines {
    -webkit-transform: scale3d(0.8, 0.8, 0.8);
    transform: scale3d(0.8, 0.8, 0.8);
  }

  .tcon-menu--xcross {
    width: auto;
  }

  .tcon-menu--xcross.tcon-transform .tcon-menu__lines {
    background: transparent;
  }

  .tcon-menu--xcross.tcon-transform .tcon-menu__lines::after,
  .tcon-menu--xcross.tcon-transform .tcon-menu__lines::before {
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    top: 0;
    width: 40px;
  }

  .tcon-menu--xcross.tcon-transform .tcon-menu__lines::before {
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    background: $color-hamburger-menu-open;
  }

  .tcon-menu--xcross.tcon-transform .tcon-menu__lines::after {
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    background: $color-hamburger-menu-open;
  }

  .tcon-visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .tcon-visuallyhidden:active,
  .tcon-visuallyhidden:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
  }
}
// Mobile menu changes.
@media handheld, only screen and (max-width: 1000px) {
  #mobile-menu {
    display: block;
    @include transition(.2s);
  }

  .wrapper {
    -webkit-transition: right 0.2s ease-in-out;
    -moz-transition: right 0.2s ease-in-out;
    -o-transition: right 0.2s ease-in-out;
    transition: right 0.2s ease-in-out;
    position: absolute;
    right: 0;
  }

  .wrapper.with-transitions {
    .footer,
    .h-bar,
    .logo,
    main {
      @include transition(.2s);
    }
  }

  .wrapper.menu-open .nav-primary {
    right: 0;
    -webkit-box-shadow: -25px 0 100px rgba(0, 0, 0, .4);
    -moz-box-shadow: -25px 0 100px rgba(0, 0, 0, .4);
    box-shadow: -25px 0 100px rgba(0, 0, 0, .4);
  }

}
