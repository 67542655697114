

.footer.footer {
  padding-top: 80px;
  padding-bottom: 20px;

  img {
    width: 100%;
    height: auto;
    max-width: 100%;
    display: block;
    @media handheld, only screen and (max-width: 767px) {
      max-width: 300px;
      margin: 0 auto;
    }
  }

  .support-by {
    p {
      margin: 0;
      padding: 0;
    }
  }

  .legal {
    padding-top: 80px;
    text-align: center;
    color: rgba(0, 0, 0, .25);
    font-size: 14px;
    a {
      color: rgba(0, 0, 0, .25);
      &:hover {
        color: $color-accent-secondary;
      }
    }
  }
}
