$color-body-on-subtle: $color-light-text;
$color-body-on-super-subtle: $color-hr;

.woocommerce {
    padding-bottom: 60px;
    .col-1 {
      width: 100%;
    }
}

.woocommerce a.add_to_cart_button,
.woocommerce a.button,
.woocommerce a.button.alt,
.woocommerce a.wc-forward,
.woocommerce button.button,
.woocommerce input.button,
.woocommerce input.button.checkout-button {
    @include butt;
    background-image: none;
    text-shadow: none;
}

.woocommerce {
  input.button:disabled,
  input.button[disabled] {
    background: rgba(0, 0, 0, .3);
    color: #fff;
  }
}

.woocommerce a.add_to_cart_button:hover,
.woocommerce a.button:hover,
.woocommerce a.wc-forward:hover,
.woocommerce button.button:hover,
.woocommerce input.button.checkout-button:hover,
.woocommerce input.button:hover {
    @include butt-hover;
    background-color: $color-accent;
    border-color: $color-accent;
    color: #fff;
    background-image: none;
    text-shadow: none;
}

.woocommerce a.button.alt.checkout-button {
    display: block;
}

.woocommerce a.button.alt.checkout-button:hover {
    background-color: $color-accent;
}

/* Add to cart */
.woocommerce button.single_add_to_cart_button.alt {
    background-color: $color-accent;
    background-image: none;
    text-shadow: none;
    font-family: $font-sans;
    //@include sans-modified;
    color: #fff;
    padding: 12.5px;
    border: 0;

    &:hover {
        background: $color-accent-secondary;
    }
}

.woocommerce input.button.alt {
    @include butt;
    background: $color-accent;
    background-image: none;
    text-shadow: none;
    padding: 30px 40px;
    border: none;
    color: #fff;
}

.woocommerce input.button.alt:hover {
    @include butt-hover;
    background-image: none;
    text-shadow: none;
    background: $color-base;
}

/*
.woocommerce .woocommerce-message, .woocommerce .woocommerce-error, .woocommerce .woocommerce-info {
  @include rounded(3px);
  background-image: none;
  text-shadow: none;
  @include box-shadow(none);
  @include inner-shadow(none);
  border: 1px solid $color-body-on-super-subtle;
  &:before {
    font-family: $font-serif;
    text-shadow: none;
    padding-top:1em;
    @include inner-shadow(0);
  }
}
.woocommerce .woocommerce-info {
  &:before {
    background-color: $color-accent;
  }
}
*/
.woocommerce .woocommerce-error,
.woocommerce .woocommerce-info,
.woocommerce .woocommerce-message {
    @include rounded(2px);
    background-image: none;
    text-shadow: none;
    @include box-shadow(none);
    @include inner-shadow(none);
    border-color: $color-accent;

    &:before {
        text-shadow: none;
        border: 0;
    }
}

.woocommerce .woocommerce-info {
    &:before {
        color: $color-accent;
    }
}

/* Payment box - appears on checkout and page page */
#payment {
    background: transparent;
    @include rounded(3px);

    ul.payment_methods {
        text-align: left;
        padding: 1em;
        border-bottom: 0;
        margin: 0;
        list-style: none outside;

        div.payment_box {
            position: relative;
            width: 96%;
            padding: 1em 2%;
            margin: 0.5em 0 1.5em 1.5em;
            font-size: 0.92em;
            @include rounded(3px);
            line-height: 1.5em;
            background-image: none;
            @include box-shadow(none);
            color: $color-base;
            text-shadow: none;
        }

        &:after {
            content: "";
            display: block;
            border: 0;
            position: absolute;
            top: 0;
            left: 0;
            margin: 0;
        }
    }
}

/* Fix checkboxes. */
input.input-checkbox {
    background-color: black;
    display: inline-block;
}

.woocommerce label.checkbox {
    display: inline-block;
    margin: 0;
    padding: 0;
}

.woocommerce p.woocommerce-result-count {
    color: $color-body-on-subtle;
    margin-bottom: 2em;
    font-size: 14px;
}

//
// Product grid display
//
.woocommerce {
    ul.products {
        li.product {
            text-align: center;

            h3 {
                line-height: 110%;
                font-family: $font-headline;
                text-transform: none;
                letter-spacing: 0;
                font-size: 17px;
            }

            img.attachment-shop_catalog {
                @include box-shadow(none);
                @include inner-shadow(none);
                border: 1px solid $color-body-on-super-subtle;
                @include transition();

                &:hover {
                    //border: 1px solid $color-accent;
                }
            }

            mark.count {
                background: transparent;
                color: $color-accent-secondary;
            }

            span.price {
                font-size: 19px;
                color: $color-base;
            }

            .ajax_add_to_cart {
                margin-bottom: 5px;
            }

            .sku {
                //@include sans-modified;
                color: $color-light-text;
            }
        }
    }

}

.woocommerce .woocommerce-billing-fields input,
html body .woocommerce input.input-text,
html body .woocommerce table.shop_table .input-text,
html body .woocommerce textarea.input-text,
input.input-text {
    margin: 0;
    box-sizing: border-box;
    padding: 9px;
    @include rounded(0);
    font-size: 16px;
    color: $color-base;
    line-height: 130%;
    @include inner-shadow(none);
    @include box-shadow(none);
    border: 1px solid $color-hr;
    background-color: transparent;
    @include transition();
}

html body .woocommerce #payment ul.payment_methods input.input-radio,
html body .woocommerce input.input-radio {
    border: 0;
}

html body .woocommerce table.shop_table .coupon .input-text {
    min-width: 150px;
}

html body .woocommerce table.shop_table .button {
    padding: 10px 18px;
}

//
// Shipping
//
html body .woocommerce {
    .woocommerce-shipping-calculator {}

    .form-row.form-row-wide {
        span {
            padding: 0;
        }
    }

    ul#shipping_method {
        li {
            label {
                span.woocommerce-Price-amount {
                    float: none;
                    position: static;
                    text-align: left;
                    display: inline-block;
                    width: auto;
                    min-width: 0;
                    padding: 0;
                    text-indent: 0;
                }

                span.woocommerce-Price-currencySymbol {
                    float: none;
                    position: static;
                    text-align: left;
                    display: inline-block;
                    width: auto;
                    min-width: 0;
                    padding: 0;
                    margin: 0;
                    text-indent: 0;
                }
            }
        }
    }
}

html body .woocommerce,
html body .woocommerce .woocommerce-MyAccount-content {
     &,
    #add_payment_method {
        #payment {
            background-color: #F7F6F7;

            ul.payment_methods {
                width: auto;
                background-color: #fff;
                border: 1px solid $color-body-on-super-subtle;

                div.payment_box,
                div.woocommerce-PaymentBox.payment_box {
                    width: auto;
                    background-color: #F7F6F7;
                    margin: 20px 0 0;

                    &:before {
                        content: "";
                        display: block;
                        border: 1em solid #F7F6F7;
                        /* arrow size / color */
                        border-right-color: transparent;
                        border-left-color: transparent;
                        border-top-color: transparent;
                        position: absolute;
                        top: -.75em;
                        left: 0;
                        margin: -1em 0 0 2em;
                    }

                    fieldset {
                        border: 0;
                        padding: 0;
                        margin: 0;
                    }

                    .input-text {
                        margin: 0;
                        box-sizing: border-box;
                        @include rounded(0);
                        border: 1px solid $color-hr;
                        background-color: #fff;
                    }

                }
            }

            .form-row {
                background: transparent;
            }
        }
    }
}

html body .woocommerce #payment div.form-row.place-order {
    padding-top: 40px;
    background-color: #fff;
}

.woocommerce a.about_paypal {
    width: auto;
    padding: 0;
    margin: 0 0 0 10px;
    line-height: 100%;
}

.woocommerce div.cart-totals table {
    padding-right: 30px;
    background-color: black;
}

html body .woocommerce div.cart-collaterals table tr td span {
    padding-left: 50px;
}

/* Register and login boxes */
.woocommerce form.checkout_coupon,
.woocommerce form.login,
.woocommerce form.register {
    @include rounded(2px);
}

.woocommerce div.create-account {
    //@include box;
    margin-top: 30px;
    margin-bottom: 30px;
}

.woocommerce table.shop_table {
    @include rounded(2px);
}

.woocommerce p.lost_password {
    margin-top: 20px;
}

.woocommerce .login input.button {
    margin-right: 20px;
}

.woocommerce h3#order_review_heading {
    margin-top: 50px;
    padding-top: 50px;
    border-top: 1px solid $color-body-on-super-subtle;
}

.single-product h1 {
    text-align: left;
    font-size: 30px;
}

.woocommerce {
    a.shipping-calculator-button {
        margin-top: 20px;
        @include butt;

        &:hover {
            @include butt-hover;
        }
    }

    nav.woocommerce-pagination {
        border: 0;
        margin-bottom: 50px;

        ul.page-numbers {
            border: 0;

            li {
                border: 0;

                span.current {
                    background: $color-base;
                    color: #fff;
                }

                a {
                    border: 0;

                    &:hover {
                        background: $color-accent-secondary;
                        color: #fff;
                    }
                }
            }
        }
    }
}

//
// Category display
//

.woocommerce {
    .cat-header-container {
        padding-bottom: 40px;
        @media handheld, only screen and (max-width: 768px) {
            padding-bottom: 20px;
        }

        .cat-image {
            float: left;
            padding-right: 30px;
            @media handheld, only screen and (max-width: 768px) {
                float: none;
                padding: 0 0 20px;
            }

            img {
                width: 200px;
                height: auto;
                border: 1px solid $color-hr;
            }
        }
    }
}

//
// My Account
//
.woocommerce {
    nav.woocommerce-MyAccount-navigation {
        padding-right: 40px;

        ul {
            &,
            li {
                margin: 0;
                padding: 0;
                list-style: none;
            }

            li {
                border-bottom: 1px solid $color-hr;

                a {
                    display: block;
                    padding: 6px;
                    color: $color-text;

                    &:hover {
                        color: $color-accent;
                    }
                }

                &.is-active {
                    background: $color-accent;

                    a {
                        color: #fff;
                    }
                }
            }
        }
    }
}

//
// Product page
//
.woocommerce {
    .summary.entry-summary {
        &,
        p {
            font-size: 16px;
        }

        h1.product_title {
            margin: 0 0 5px;
        }

        p.price {
            font-size: 22px;
            color: $color-base;
            margin: 0 0 15px;
        }
    }
}

//
// Related products
//
.related.products {
    clear: both;
    //border-top: 1px solid $color-hr;
    padding-top: 100px;
}

//
// Images
//
.woocommerce {
    .images {
        img {
            border: 1px solid $color-hr;
        }
    }
}

//
// Headings
//
.woocommerce {
    h2 {
        color: $color-base;
    }
}

// Store info notice.
.woocommerce {
    .box.store-notice {
        text-align: center;
        display: inline-block;
        margin: 0 auto;
        color: $color-base;
        border: 1px solid $color-accent;
        margin-bottom: 20px;
        font-family: $font-headline;
    }
}

//
// Store header
//
.woocommerce {
    .store-header {
        margin-bottom: 20px;
        margin-top: 20px;
        @media handheld, only screen and (max-width: 768px) {
            text-align: center;
            background: transparent;
            padding: 0;

            .links-container {
                border: 1px solid $color-hr;
                padding: 6px;
                display: block;
            }
        }

        nav.woocommerce-breadcrumb {
            float: left;
            padding: 0;
            margin: 0;
            color: rgba(0, 0, 0, .7);

            a {
                color: rgba(0, 0, 0, .7);
                border: 0;
                font-weight: 500;
            }

            span {
                font-weight: 300;
                color: rgba(0, 0, 0, .15);
                padding: 0 1px;
            }
            @media handheld, only screen and (max-width: 768px) {
                float: none;
                margin-bottom: 10px;
            }

        }

        a.cart {
            display: block;
            float: right;
            background: url(#{$images}/shop-menu-sprites.png) 0 3px no-repeat;
            background-size: 25px auto;
            padding: 5px 0 5px 32px;
            line-height: 100%;
            font-size: 13px;
            margin-left: 25px;
            color: $color-base;
            &:hover {
                @include opacity(.8);
            }
            @media handheld, only screen and (max-width: 768px) {
                float: none;
                display: inline-block;
                margin-left: 0;
                margin-right: 15px;
            }
        }

        a.my-account {
            display: block;
            float: right;
            background: url(#{$images}/shop-menu-sprites.png) 0 -74px no-repeat;
            background-size: 25px auto;
            padding: 5px 0 5px 31px;
            line-height: 100%;
            font-size: 13px;
            color: $color-base;

            &:hover {
                @include opacity(.8);
            }
            @media handheld, only screen and (max-width: 768px) {
                float: none;
                display: inline-block;
            }
        }

    }
}



//
// Fix issues with Bootstrap
//
.woocommerce {
  .checkout {
    .row {
      margin: 0 -15px;
      padding: 0;
    }
  }
}
