.page-heading {
  margin: 50px 0;

  .col {
    text-align: center;

    h1 {
      font-family: $font-body;
      font-size: 17px;
      margin: 0;
      color: $color-text;

      html.wf-active & {
        font-size: 17px;
        line-height: 100%;
        text-transform: uppercase;
        letter-spacing: 0.08em;
      }
    }
    span {
      width: 50px;
      height: 1px;
      border: 0;
      border-top: 1px solid $color-accent-secondary;
      margin: 20px auto;
      display: block
    }
    h2 {
      font-size: 29px;
      margin: 0;
      html.wf-active & {
        font-size: 34px;
        line-height: 100%;
      }
    }

  }
}

// Hide on home
body.home {
  section.categories {
    .row {
      .grid-item {
        &.loaded {
          &.introduction {
            display: none;
          }
        }
      }
    }
  }
}

section.categories {

  background: url("#{$images}/paper-background.png") center center repeat;
  background-color: $color-base;
  background-size: 300px auto;
  .row {
    padding: 0;
    margin: 0;
    .grid-item {
      background: url("#{$images}/loading.gif") center center no-repeat;
      background-size: 60px 60px;
      padding: 0 !important;
      margin: 0 !important;
      line-height: 100%;

      &.loaded {
        background-size: cover;
        padding: 0;
        a {
          background: url("#{$images}/paper-background.png") center center repeat;
          background-color: rgba(77, 87, 94, .4);
          background-size: 300px auto;
          //background: rgba(63, 54, 48, .6);
          //background: rgba(79, 60, 39, .6);
          &:hover, &:active {
            background-color: rgba(117, 137, 158, .1);
          }
        }
        &.introduction {
          display: block;
          a {

            background: url("#{$images}/paper-background.png") center center repeat;
            background-color: $color-base;
            background-size: 300px auto;
            text-shadow: none;
            font-family: $font-sans;
            font-size: 15px;
            letter-spacing: .1em;
            text-transform: uppercase;
            &:hover {
              background-color: lighten($color-base, 10%);
            }
          }
        }
      }
      p, h1, h2, h3, h4 {
        margin: 0;
        padding: 0;
      }
      a {
        min-height: 250px;
        background: rgba(43, 30, 18, .6);
        margin: 0;
        padding: 0 25px;
        color: #fff;
        text-shadow: 0 0 7px rgba(0, 0, 0, .8);
        @include flex-it;
        @include flex-align-item-center;
        @include flex-justify-center;
      }
    }
  }
}



.header {
  top: -340px;
  @media handheld, only screen and (max-width: 991px) {
    top: -320px;
  }
  @media handheld, only screen and (max-width: 768px) {
    top: -360px;
  }
  @media handheld, only screen and (max-width: 575px) {
    top: -625px;
  }
}
.slide-down-nav {
  nav {
    section.categories {
      .row {
        .grid-item {
          a {
            min-height: 100px;
            @media handheld, only screen and (max-width: 991px) {
              min-height: 70px;
            }
            @media handheld, only screen and (max-width: 768px) {
              min-height: 65px;
            }
          }
        }
        .grid-item.introduction {
          a {
            min-height: 50px;
            @media handheld, only screen and (max-width: 991px) {
              min-height: 50px;
            }
            @media handheld, only screen and (max-width: 768px) {
              min-height: 50px;
            }
          }
        }
      }
    }
  }
}





.header {
  position: fixed;
  width: 100%;
  z-index: 8000;
  @include transition();

  section.categories {
    @include drop-shadow(0, .4, 50px, 0)
  }

  &.open {
    top: 0;
    section.categories {
      @include drop-shadow(0, .8, 80px, 0)
    }
  }
}

body.hidemenu {
  header {
    top: -800px;
  }
}

.slide-down-nav {
  text-align: center;

  .slide-nav-trigger {
    background: url("#{$images}/paper-background.png") center center repeat;
    background-color: $color-base;
    background-size: 300px auto;
    @include rounded(0 0 5px 5px);
    display: inline-block;
    margin: 0 auto;
    color: #fff;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: .1em;
    padding: 15px 20px;
    @include transition();
    &:hover {
      background-color: lighten($color-base, 10%);
    }

    &:hover {
      //padding-top: 17px;
      //padding-bottom: 17px;
      cursor: pointer;
    }
  }
}



.page-overlay {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: rgba(51, 38, 27, .7);
  background: url("#{$images}/paper-background.png") center center repeat;
  background-color: rgba(48, 54, 58, 1);
  background-size: 300px auto;
  z-index: 2000;
  display: none;
  @include transition(.2s);
  @include opacity();
  .menu-open & {
    display: block;

    &.with-transitions {
      @include opacity(.6);
    }
  }

}



//
// Front page
//
section.reflections-intro-logo {
  .row {
    img {
      width: 100%;
      height: auto;
      display: block;
      max-width: 400px;
      margin: 0 auto;
    }
  }
}
