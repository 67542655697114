@import "common/variables";

// Automatically injected Bower dependencies via wiredep (never manually edit this block)
// bower:scss
@import "../../bower_components/bootstrap/scss/bootstrap.scss";
// endbower

@import "components/mixins";
@import "components/flexbox-mixins";
@import "common/global";
@import "components/buttons";
//@import "components/comments";
@import "components/forms";
@import "components/grid";
@import "components/flexslider";
@import "components/wp-classes";
@import "layouts/header";
@import "layouts/sidebar";
@import "layouts/footer";
@import "layouts/pages";
@import "layouts/posts";
@import "layouts/tinymce";
@import "layouts/woocommerce";
@import "components/social-media";
@import "components/instagram";
@import "flexible-content/flexible-content.scss";

@import "components/reflections";

@import "../../assets/scripts/vendor/featherlight-1.7.1/release/featherlight.min.css";
