

.ref-map, .gf-map {
  background: rgba(0, 0, 0, .05) url("#{$images}/loading.gif") center center no-repeat;
  background-size: 50px;
}

.ref-map, .lab-modal {
  height: 800px;
}

.gf-map {
  height: 300px;
  width: 100%;
}

body.noscroll {
  overflow: hidden;
}


.lab-modal {
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  position: relative;
  //background: rgba(0, 0, 0, .4);
  min-height: 500px;
  position: fixed;
  z-index: 9000;
  top: 0;
  text-align: center;
  display: none;
  @include opacity(0);
  @include transition();

  &.show {
    display: block;
  }
  &.with-transitions {
    @include opacity(1);
  }

  // Close modal button
  a.top-close {
    display: inline-block;
    margin: 5px 4% 0 0;
    float: right;
    text-transform: uppercase;
    padding: 7px 12px 7px;
    font-size: 24px;
    line-height: 100%;
    color: #fff;
    font-size: 18px;
    background: $color-accent-secondary;
    @include rounded(3px 3px 0 0);

    &:hover {
      background: $color-accent;
    }
  }



  .content {
    text-align: left;
    background: #F4F2F0;
    background: #F4F2F0 url("#{$images}/paper.jpg") center center repeat;
    @include drop-shadow(0, .4, 50px, 0)
    background-size: 300px auto;
    padding: 30px;
    display: block;
    width: 94%;
    height: 92%;
    overflow: scroll;
    margin: 0 auto;
    @include rounded(4px);
    h2 {
      text-align: center;
    }
  }
}

//
  // Social buttons
  //
  .entry-share {
    text-align: center;
    ul.entry-share-btns {
      li.entry-share-btn {
        a {
          background: $color-accent-secondary;
          color: #fff;
          &:hover {
            background: $color-accent;
          }
        }
      }
    }
  }



//
// Story view
//
section.gallery {
  text-align: center;
  img {
    display: block;
    max-width: 100%;
    height: auto;
    margin: 0 auto;
  }
}
